/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppSelector } from "hooks/useAppSelector";
import ActivityOverlay from "components/ActivityOverlay/ActivityOverlay";
import { TransactionSteps, TransactionType } from "utils/constants";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  rejectTransaction,
  setTransactionApproval,
  setEditTransactionInfo,
  loadNewTransactionPaymentOptions,
  setTransactionDetails,
  editTransaction,
  setNomineeDetails,
  resetEditTransactionError,
} from "store/transaction/slice";
import RouteConfig from "routes/RouteConfig";
import { useNavigate } from "react-router-dom";

import { getDistributorDetails } from "utils/getDistributorDetails";
import NewSipLumpsumTransactionDetails from "components/TransactionDetails/NewSipLumpsumTransactionDetails";
import { incrementTransactionStage } from "store/appstate/slice";
import ToastCenter from "utils/ToastCenter";

const NewSipTransactionDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    transactionDetails,
    transactionDistributor,
    isLoadingPaymentOptions,
    paymentOptionsError,
    isLoadingTransactionSchemesDetails,
    isRejectingTransaction,
    didRejectTransaction,
    rejectTransactionError,
    nomineeDetails,
    didEditTransaction,
    editTransactionError,
  } = useAppSelector((state) => state.transaction);
  const { transactionType } = useAppSelector((state) => state.appState);

  const [tempNomineeDetailsStore, setTempNomineeDetailsStore] =
    useState<any>(null);
  useEffect(() => {
    if (didRejectTransaction) {
      // dispatch(resetOtpStates());
      // dispatch(resetConfirmTransactionStates());
      navigate(RouteConfig.newSipReject);
    }
  }, [didRejectTransaction]);

  useEffect(() => {
    if (editTransactionError) {
      ToastCenter.error(editTransactionError);
      dispatch(resetEditTransactionError());
    }
    if (didEditTransaction) {
      createNewTransactionDetails(tempNomineeDetailsStore);
      navigate(RouteConfig.newSipTransactionDetails);
      dispatch(resetEditTransactionError());
    }
  }, [didEditTransaction, editTransactionError]);
  const handleTransactionReject = () => {
    const distributor = getDistributorDetails(transactionDistributor);
    dispatch(
      rejectTransaction({
        batchNo: transactionDetails.fetchFreshCartDataResponseList.batchNo,
        pan: transactionDetails.fetchFreshCartDataResponseList.ap1Pan,
        ...distributor,
        rejectReason: "Not Intrested",
      })
    );
  };

  const handleTransactionApprove = () => {
    // if (transactionDetails.fetchFreshCartDataResponseList.nominee2Allocation) {
    //   if (
    //     transactionDetails.fetchFreshCartDataResponseList.nominee3Allocation
    //   ) {
    //     const sum =
    //       parseFloat(
    //         transactionDetails.fetchFreshCartDataResponseList.nominee1Allocation
    //       ) +
    //       parseFloat(
    //         transactionDetails.fetchFreshCartDataResponseList.nominee2Allocation
    //       ) +
    //       parseFloat(
    //         transactionDetails.fetchFreshCartDataResponseList.nominee3Allocation
    //       );
    //     if (sum > 100) {
    //       return;
    //     }
    //   } else {
    //     const sum =
    //       parseFloat(
    //         transactionDetails.fetchFreshCartDataResponseList.nominee1Allocation
    //       ) +
    //       parseFloat(
    //         transactionDetails.fetchFreshCartDataResponseList.nominee2Allocation
    //       );
    //     if (sum > 100) {
    //       return;
    //     }
    //   }
    // } else {
    //   if (
    //     parseFloat(
    //       transactionDetails.fetchFreshCartDataResponseList.nominee1Allocation
    //     ) > 100
    //   ) {
    //     return;
    //   }
    // }
    dispatch(
      loadNewTransactionPaymentOptions({
        bankName:
          transactionDetails.fetchFreshCartDataResponseList.payoutBankName,
        ifsc: transactionDetails.fetchFreshCartDataResponseList.payoutIFSCCode,
        guid: transactionDetails.fetchFreshCartDataResponseList.batchNo,
        accNo: transactionDetails.fetchFreshCartDataResponseList.payoutBankAcNo,
      })
    );
    dispatch(incrementTransactionStage());
    dispatch(setTransactionApproval(true));
    navigate(RouteConfig.paymentOptions);
  };
  const handleEditTransaction = (data: any, _type: string | undefined) => {
    dispatch(setEditTransactionInfo(data));
    if (_type === "Investment") {
      navigate(RouteConfig.newSipEdit);
    } else if (
      _type === "Applicant_1" ||
      _type === "Applicant_2" ||
      _type === "Applicant_3"
    ) {
      navigate(RouteConfig.newTransactionEdit);
    } else if (
      _type === "Nominee1" ||
      _type === "Nominee2" ||
      _type === "Nominee3"
    ) {
      navigate(RouteConfig.newTransactionNomineeEdit);
    }
  };

  const createNewTransactionDetails = (nomineeValues: any) => {
    const transactionData = {
      ...transactionDetails?.fetchFreshCartDataResponseList,
      ...nomineeValues,
    };
    const finalTransactionData = {
      fetchFreshCartDataResponseList: transactionData,
    };
    dispatch(setTransactionDetails(finalTransactionData));
  };

  const handleDeleteTransactionCall = (nomineeDetailsVal: any) => {
    const editTransactionData = {
      transactionType:
        transactionType === TransactionType.newLumpsum ? "Lumpsum" : "SIP",
      batchNo: transactionDetails?.fetchFreshCartDataResponseList?.batchNo,
      transactionDateTime:
        transactionDetails?.fetchFreshCartDataResponseList?.transactionDateTime,
      activity: "",
      newUser: true,
    };

    const nomineeDetailsTemp = {
      nominee1Address: nomineeDetailsVal?.[0]?.nomineeAddress || "",
      nominee1Allocation: nomineeDetailsVal?.[0]?.nomineeAllocation || "",
      nominee1DOB: nomineeDetailsVal?.[0]?.nomineeDOB || "",
      nominee1GuardianName: nomineeDetailsVal?.[0]?.nomineeGuardianName || "",
      nominee1GuardianPan: nomineeDetailsVal?.[0]?.nomineeGuardianPan || "",
      nominee1Name: nomineeDetailsVal?.[0]?.nomineeName || "",
      nominee1Pan: nomineeDetailsVal?.[0]?.nomineePan || "",
      nominee1Relation: nomineeDetailsVal?.[0]?.nomineeRelation || "",
      nominee1RelationshipWithGuardian:
        nomineeDetailsVal?.[0]?.nomineeRelationshipWithGuardian || "",
      nominee1TypeoOfGuardian:
        nomineeDetailsVal?.[0]?.nomineeTypeoOfGuardian || "",

      nominee2Address: nomineeDetailsVal?.[1]?.nomineeAddress || "",
      nominee2Allocation: nomineeDetailsVal?.[1]?.nomineeAllocation || "",
      nominee2DOB: nomineeDetailsVal?.[1]?.nomineeDOB || "",
      nominee2GuardianName: nomineeDetailsVal?.[1]?.nomineeGuardianName || "",
      nominee2GuardianPan: nomineeDetailsVal?.[1]?.nomineeGuardianPan || "",
      nominee2Name: nomineeDetailsVal?.[1]?.nomineeName || "",
      nominee2Pan: nomineeDetailsVal?.[1]?.nomineePan || "",
      nominee2Relation: nomineeDetailsVal?.[1]?.nomineeRelation || "",
      nominee2RelationshipWithGuardian:
        nomineeDetailsVal?.[1]?.nomineeRelationshipWithGuardian || "",
      nominee2TypeoOfGuardian:
        nomineeDetailsVal?.[1]?.nomineeTypeoOfGuardian || "",

      nominee3Address: nomineeDetailsVal?.[2]?.nomineeAddress || "",
      nominee3Allocation: nomineeDetailsVal?.[2]?.nomineeAllocation || "",
      nominee3DOB: nomineeDetailsVal?.[2]?.nomineeDOB || "",
      nominee3GuardianName: nomineeDetailsVal?.[2]?.nomineeGuardianName || "",
      nominee3GuardianPan: nomineeDetailsVal?.[2]?.nomineeGuardianPan || "",
      nominee3Name: nomineeDetailsVal?.[2]?.nomineeName || "",
      nominee3Pan: nomineeDetailsVal?.[2]?.nomineePan || "",
      nominee3Relation: nomineeDetailsVal?.[2]?.nomineeRelation || "",
      nominee3RelationshipWithGuardian:
        nomineeDetailsVal?.[2]?.nomineeRelationshipWithGuardian || "",
      nominee3TypeoOfGuardian:
        nomineeDetailsVal?.[2]?.nomineeTypeoOfGuardian || "",

      idonotWishToNominee: nomineeDetailsVal?.length > 0 ? "Y" : "N",
    };

    const finalEditTransactionData = {
      ...transactionDetails?.fetchFreshCartDataResponseList,
      ...nomineeDetailsTemp,
      ...editTransactionData,
    };

    setTempNomineeDetailsStore(nomineeDetailsTemp);
    dispatch(editTransaction(finalEditTransactionData));
    // createNewTransactionDetails(nomineeDetailsTemp);
    // navigate(RouteConfig.newLumpsumTransactionDetails);
  };
  const handleDeleteTransaction = (data: any, type: string | undefined) => {
    let tempNomineeDetails = [...nomineeDetails];
    if (type === "Nominee1") {
      tempNomineeDetails?.splice(0, 1);
    } else if (type === "Nominee2") {
      tempNomineeDetails?.splice(1, 1);
    } else if (type === "Nominee3") {
      tempNomineeDetails?.splice(2, 1);
    }
    dispatch(setNomineeDetails(tempNomineeDetails));
    handleDeleteTransactionCall(tempNomineeDetails);
  };

  return (
    <>
      {!isLoadingTransactionSchemesDetails ? (
        <NewSipLumpsumTransactionDetails
          stepper={{ currentIndex: 0, steps: TransactionSteps }}
          transactions={[]}
          error={paymentOptionsError || rejectTransactionError}
          onApproveTranaction={handleTransactionApprove}
          onRejectTranaction={handleTransactionReject}
          onEditTransaction={handleEditTransaction}
          onDeleteTransaction={handleDeleteTransaction}
        />
      ) : null}
      {(isLoadingPaymentOptions ||
        isLoadingTransactionSchemesDetails ||
        isRejectingTransaction) && <ActivityOverlay />}
    </>
  );
};
export default NewSipTransactionDetails;
