import axios from "axios";

export const setBaseURL = () => {
  // axios.defaults.baseURL = "https://mfpartneruatdev.invescomutualfund.com/api/";
  axios.defaults.baseURL = "https://mfpartneruat.invescomutualfund.com/api/";
  // axios.defaults.baseURL = "https://mfpartner.invescomutualfund.com/api/";
};
export const setHeaderDeviceId = (_deviceId: any) => {
  axios.defaults.headers.common["device-id"] = _deviceId;
};

let token: any = null;
export const setHeaderAccessToken = (_token: any) => {
  token = _token;
  axios.defaults.headers.common["x-auth-token"] = token;
};
export const getAccessToken = () => token;
